export default {
  zh: {
    "web-use": [
      {
        label: "注册/登录",
        id: "reg-login",
      },
      {
        label: "个人中心",
        id: "user-center",
      },
      {
        label: "个人信息",
        id: "user-center-1",
        isChild: true,
      },
      {
        label: "账户管理",
        id: "user-center-2",
        isChild: true,
      },
      {
        label: "设置/修改密码",
        id: "user-center-3",
        isChild: true,
      },
      {
        label: "购买服务",
        id: "pay",
      },
      {
        label: "专业版服务",
        id: "pay-1",
        isChild: true,
      },
      {
        label: "企业版服务",
        id: "pay-2",
        isChild: true,
      },
      {
        label: "下载 DEC Hub",
        id: "download-dec",
      },
    ],
    "dec-install": [
      {
        label: "DEC Hub 安装",
        id: "dec-install",
      },
      {
        label: "DEC Hub 使用",
        id: "dec-use",
      },
      {
        label: "DEC Hub 登录",
        id: "dec-login",
        isChild: true,
      },
      {
        label: "激活专业版",
        id: "dec-major",
        isChild: true,
      },
      {
        label: "增加每日授权设备数",
        id: "add-platform-number",
        isChild: true,
      },
      {
        label: "专业版局域网授权",
        id: "dec-major-1",
        isChild: true,
      },
      {
        label: "企业版局域网授权",
        id: "dec-compant",
        isChild: true,
      },
      {
        label: "安装DEC",
        id: "dec-download",
      },
      {
        label: "启动DEC",
        id: "open-dec",
      },
    ],
    "dec-use": [
      {
        label: "Select Engine（选择引擎）",
        id: "select-engine",
      },
      {
        label: "Material（材质）",
        id: "material",
      },
      {
        label: "LeveI Map（保存关卡）",
        id: "lecel-map",
      },
      {
        label: "Render Settings（渲染设置）",
        id: "render-settings",
      },
      {
        label: "Render range（渲染区间）",
        id: "render-range",
      },
      {
        label: "Render（渲染）",
        id: "render",
      },
      {
        label: "Movie Render Queue（高质量渲染）",
        id: "movie-render",
      },
      {
        label: "Set Camera（同步镜头）",
        id: "set-camera",
      },
      {
        label: "Export Scene（导出场景）",
        id: "export-scene",
      },
      {
        label: "Use Cached GeometryExtractor（几何信息缓存）",
        id: "mesh-streaming",
      },
      {
        label: "Delay Render（延迟渲染）",
        id: "add-engine-asset",
      },
      {
        label: "Normal Tangent Streaming（流送法线、切线）",
        id: "normal-tangent",
      },
      {
        label: "Create Engine Transform Delegate（添加DEC节点代理）",
        id: "add-engine-transform",
      },

      {
        label: "Manage Engine Transform Delegate（DEC节点列表）",
        id: "manage-engine-transform",
      },
      {
        label: "Create Engine Asset Node（添加引擎节点）",
        id: "create-engine-node",
      },
      {
        label: "Set Engine Asset Path（引擎资产路径）",
        id: "set-asset-path",
      },
      {
        label: "About（关于我们）",
        id: "about",
      },
      {
        label: "DEC支持MAYA+UE版本",
        id: "dec-use-maya",
      },
    ],
  },
  en: {
    "web-use": [
      {
        label: "Sign-up/Sign-in",
        id: "reg-login",
      },
      {
        label: "Personal Center",
        id: "user-center",
      },
      {
        label: "Personal Information",
        id: "user-center-1",
        isChild: true,
      },
      {
        label: "Order Information",
        id: "user-center-2",
        isChild: true,
      },
      {
        label: "Setting/Modifying Password",
        id: "user-center-3",
        isChild: true,
      },
      {
        label: "Purchasing Service",
        id: "pay",
      },
      {
        label: "Pro Edition Service",
        id: "pay-1",
        isChild: true,
      },
      {
        label: "Enterprise Edition Service",
        id: "pay-2",
        isChild: true,
      },
      {
        label: "Downloading the DEC Hub",
        id: "download-dec",
      },
    ],
    "dec-install": [
      {
        label: "Installation of the DEC Hub",
        id: "dec-install",
      },
      {
        label: "Usage of the DEC Hub",
        id: "dec-use",
      },
      {
        label: "Sign-in of the DEC Hub",
        id: "dec-login",
        isChild: true,
      },
      {
        label: "Activating the Pro Edition",
        id: "dec-major",
        isChild: true,
      },
      {
        label: "Increasing the number of authorized devices per day",
        id: "add-platform-number",
        isChild: true,
      },
      {
        label: "LAN authorization of the Pro Edition",
        id: "dec-major-1",
        isChild: true,
      },
      {
        label: " LAN authorization of the Enterprise Edition",
        id: "dec-compant",
        isChild: true,
      },
      {
        label: "DEC Installation",
        id: "dec-download",
      },
      {
        label: "Starting the DEC",
        id: "open-dec",
      },
    ],
    "dec-use": [
      {
        label: "Select Engine",
        id: "select-engine",
      },
      {
        label: "Material",
        id: "material",
      },
      {
        label: "LeveI Map",
        id: "lecel-map",
      },
      {
        label: "Render Settings",
        id: "render-settings",
      },
      {
        label: "Render range",
        id: "render-range",
      },
      {
        label: "Render",
        id: "render",
      },
      {
        label: "Movie Render Queue",
        id: "movie-render",
      },

      {
        label: "Set Camera",
        id: "set-camera",
      },
      {
        label: "Export Scene",
        id: "export-scene",
      },

      {
        label: "Use Cached GeometryExtractor",
        id: "mesh-streaming",
      },
      {
        label: "Delay Render",
        id: "add-engine-asset",
      },
      {
        label: "Normal Tangent Streaming",
        id: "normal-tangent",
      },
      {
        label: "Create Engine Transform Delegate",
        id: "add-engine-transform",
      },

      {
        label: "Manage Engine Transform Delegate",
        id: "manage-engine-transform",
      },
      {
        label: "Create Engine Asset Node",
        id: "create-engine-node",
      },
      {
        label: "Set Engine Asset Path",
        id: "set-asset-path",
      },
      {
        label: "About",
        id: "about",
      },
      {
        label: "DEC supports MAYA+UE version",
        id: "dec-use-maya",
      },
    ],
  },
};
